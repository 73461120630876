<template>
  <div v-show="connected" id="root">
    <h2>设置 token display</h2>
    <form @submit.prevent="demo">
      TokenTag：<input type="text" v-model="tokenTag"> <br/><br/>
      Display：<input type="text" v-model="display"> <br/><br/>
      <br/><br/>
      <button>提交</button>
      <br/><br/>
      <pr>everTxStatus: {{everTxStatus}}</pr>
    </form>
  </div>
</template>

<script>
import Everpay from 'everpay'
import pubsub from 'pubsub-js'
export default {
  name: "SetTokenDisplay",
  data() {
    return {
      tokenTag: '',
      display: '',
      everpay: Everpay,
      connected: false,
      everTxStatus: {},
    }
  },
  methods: {
    async demo(){
      let dp = false
      if (this.display === 'true') {
        dp = true
      }
      const ats = await this.everpay.signTokenDisplaySet(this.tokenTag, dp)
      console.log(ats)
      const { everHash } = await this.everpay.setTx(ats)
      const res = await this.everpay.txByHash(everHash)
      this.everTxStatus = {
        everHash: everHash,
        status: res.status,
        internalStatus: res.internalStatus
      }
    }
  },
  mounted() {
    this.pubId = pubsub.subscribe('connected',async (msgName,data)=>{
      this.connected = true
      this.everpay = data
    })
  },
  beforeDestroy() {
    pubsub.unsubscribe(this.pubId)
  },
}
</script>

<style scoped>

</style>
