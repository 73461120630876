<template>
  <div v-show="connected" id="root">
    <h2>添加 targetChain</h2>
    <form @submit.prevent="demo">
      TokenTag：<input type="text" v-model="tokenTag"> <br/><br/>
      targetChainId：<input type="text" v-model="targetChain.targetChainId"> <br/><br/>
      targetChainType：<input type="text" v-model="targetChain.targetChainType"> <br/><br/>
      targetDecimals：<input type="number" v-model="targetChain.targetDecimals"> <br/><br/>
      targetTokenId：<input type="text" v-model="targetChain.targetTokenId"> <br/><br/>
      <br/><br/>
      <button>提交</button>
      <br/><br/>
      <pr>everTxStatus: {{everTxStatus}}</pr>
    </form>
  </div>
</template>

<script>
import Everpay from 'everpay'
import pubsub from 'pubsub-js'
export default {
  name: "AddTargetChain",
  data() {
    return {
      tokenTag: '',
      targetChain:  {
        targetChainId: '',
        targetChainType: '',
        targetDecimals: 18,
        targetTokenId: ''
      },
      everpay: Everpay,
      connected: false,
      everTxStatus: {},
    }
  },
  methods: {
    async demo(){
      const targetChainMeta = {
        targetChainId: this.targetChain.targetChainId,
        targetChainType: this.targetChain.targetChainType,
        targetDecimals: +this.targetChain.targetDecimals,
        targetTokenId: this.targetChain.targetTokenId
      }
      const ats = await this.everpay.signAddTargetChainSet(this.tokenTag, targetChainMeta)
      console.log(ats)
      const { everHash } = await this.everpay.setTx(ats)
      const res = await this.everpay.txByHash(everHash)
      this.everTxStatus = {
        everHash: everHash,
        status: res.status,
        internalStatus: res.internalStatus
      }
    }
  },
  mounted() {
    this.pubId = pubsub.subscribe('connected',async (msgName,data)=>{
      this.connected = true
      this.everpay = data
    })
  },
  beforeDestroy() {
    pubsub.unsubscribe(this.pubId)
  },
}
</script>

<style scoped>

</style>
