<template>
  <div v-show="connected" id="root">
    <h2>添加新的 token</h2>
    <form @submit.prevent="demo">
      TokenId：<input type="text" v-model="newToken.tokenID"> <br/><br/>
      Symbol：<input type="text" v-model="newToken.symbol"> <br/><br/>
      ChainType：<input type="text" v-model="newToken.chainType"> <br/><br/>
      ChainId：<input type="text" v-model="newToken.chainID"> <br/><br/>
      EverDecimals：<input type="text" v-model="newToken.everDecimals"> <br/><br/>

      TargetChain <br/><br/>
      TargetChainId: <input type="text" v-model="newToken.targetChain.targetChainId"> <br/><br/>
      TargetChainType: <input type="text" v-model="newToken.targetChain.targetChainType"> <br/><br/>
      TargetDecimals: <input type="number" v-model="newToken.targetChain.targetDecimals"> <br/><br/>
      TargetTokenId: <input type="text" v-model="newToken.targetChain.targetTokenId"> <br/><br/>
      <br/><br/>
     <button>提交</button>
      <br/><br/>
      <pr>everTxStatus: {{everTxStatus}}</pr>
    </form>
  </div>
</template>

<script>
import Everpay from 'everpay'
import pubsub from 'pubsub-js'
export default {
  name: "AddToken",
  data() {
    return {
      newToken: {
        tokenID:"",
        symbol:"",
        chainType:"",
        chainID:"",
        everDecimals: 18,
        targetChain: {
          targetChainId: "",
          targetChainType:"",
          targetDecimals: 18,
          targetTokenId:""
        }
      },
      everpay: Everpay,
      connected: false,
      everTxStatus: {},
    }
  },
  methods: {
    async demo(){
      const newToken = {
        tokenID: this.newToken.tokenID,
        symbol: this.newToken.symbol,
        chainType: this.newToken.chainType,
        chainID: this.newToken.chainID,
        everDecimals: +this.newToken.everDecimals,
        targetChains: [
          {
            targetChainId: this.newToken.targetChain.targetChainId,
            targetChainType: this.newToken.targetChain.targetChainType,
            targetDecimals: +this.newToken.targetChain.targetDecimals,
            targetTokenId: this.newToken.targetChain.targetTokenId
          }
        ]
      }
      const ats = await this.everpay.signAddTokenSet(newToken)
      const { everHash } = await this.everpay.setTx(ats)
     const res = await this.everpay.txByHash(everHash)
      this.everTxStatus = {
        everHash: everHash,
        status: res.status,
        internalStatus: res.internalStatus
      }
    }
  },
  mounted() {
    this.pubId = pubsub.subscribe('connected',async (msgName,data)=>{
      this.connected = true
      this.everpay = data
    })
  },
  beforeDestroy() {
    pubsub.unsubscribe(this.pubId)
  },
}
</script>

<style scoped>

</style>
