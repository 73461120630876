<template>
  <div>
    <h1>{{signerAddr}}</h1>
    <button class="btn btn-danger" @click="connectEccWallet" v-show="!connected">metamask</button>
    <br><br>
    <button class="btn btn-danger" @click="connectRsaWallet" v-show="!connected">arConnect</button>
  </div>
</template>


<script>
import pubsub from 'pubsub-js'
import Everpay from 'everpay'
import { checkArPermissions } from 'everpay/cjs/lib/arweave'
import { providers } from 'ethers'
function isDevEnv() {
  let devEnv = false
  const hostname = window.location.hostname
  if ( hostname.split(".")[0].indexOf("dev") !== -1 || hostname === "localhost") { // test env
    devEnv = true
  }
  return devEnv
}

export default {
  name: "ConnectWallet",
  data() {
    return {
      connected: false,
      signerAddr:"",
    }
  },
  methods: {
    async connectEccWallet() {
      await window.ethereum.enable()
      const provider = new providers.Web3Provider(window.ethereum)
      await provider._ready()
      const accounts = await provider.listAccounts()
      const account = accounts[0] ?? ''
      const everpay = new Everpay({
        debug: isDevEnv(),
        account: account,
        chainType: 'ethereum',
        ethConnectedSigner: provider.getSigner()
      })
      this.signerAddr = account.toString()
      this.connected = true
      pubsub.publish('connected',everpay)
    },
    async connectRsaWallet() {
      await checkArPermissions([
        'ACCESS_ADDRESS',
        'ACCESS_ALL_ADDRESSES',
        'ACCESS_PUBLIC_KEY',
        'SIGN_TRANSACTION',
        'SIGNATURE'
      ])

      const arAddress = await window.arweaveWallet.getActiveAddress()
      const everpay = new Everpay({
        debug:isDevEnv(),
        account: arAddress,
        chainType: 'arweave',
        arJWK: 'use_wallet',
      })

      this.signerAddr = arAddress
      this.connected = true
      pubsub.publish('connected',everpay)
    }
  }
}
</script>

<style scoped>

</style>
