<template>
  <div v-show="connected" id="root">
    <h2>设置 owner</h2>
    <form @submit.prevent="demo">
      NewOwner：<input type="text" v-model="newOwner"> <br/><br/>
      <br/><br/>
      <button>提交</button>
      <br/><br/>
      <pr>everTxStatus: {{everTxStatus}}</pr>
    </form>
  </div>
</template>

<script>
import Everpay from 'everpay'
import pubsub from 'pubsub-js'
export default {
  name: "TransferOwnership",
  data() {
    return {
      newOwner: '0x63D5BDABA94a5FcCb8980BdC738e017DF5F9A1FD',
      everpay: Everpay,
      connected: false,
      everTxStatus: {},
    }
  },
  methods: {
    async demo(){
      const ats = await this.everpay.signOwnershipSet(this.newOwner)
      console.log(ats)
      const { everHash } = await this.everpay.setTx(ats)
      const res = await this.everpay.txByHash(everHash)
      this.everTxStatus = {
        everHash: everHash,
        status: res.status,
        internalStatus: res.internalStatus
      }
    }
  },
  mounted() {
    this.pubId = pubsub.subscribe('connected',async (msgName,data)=>{
      this.connected = true
      this.everpay = data
    })
  },
  beforeDestroy() {
    pubsub.unsubscribe(this.pubId)
  },
}
</script>

<style scoped>

</style>
